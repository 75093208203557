import Box from 'UIKit/Box/Box'
import { BoxProps } from 'UIKit/Box/types'
import Image from 'UIKit/Image'
import { ProviderInfo } from 'config/types'
import React from 'react'
import styled from 'styled-components'

const WrapBannerGame = styled(Box)`
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  scroll-snap-align: start;
  scroll-snap-stop: normal;
  border-radius: 4px;

  overflow: hidden;
`

const ProviderBannerItem: React.FC<BoxProps & { provider: ProviderInfo }> = ({ provider, ...props }) => {
  return (
    <WrapBannerGame {...props}>
      <Box
        style={{
          background: 'rgba(35, 44, 61, 0.6)',
        }}
        className="container-provider-item"
        position="relative"
        width="100%"
        paddingBottom="40%"
        borderRadius="8px"
        overflow="hidden"
      >
        <StyledImage src={provider.logo} alt="banner-game" width={140} height={60} />
      </Box>
    </WrapBannerGame>
  )
}

const StyledImage = styled(Image)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 8px;

  transition: ${({ theme }) => theme.transitions.fast};

  :hover {
    transform: translate(-50%, -50%) scale(1.05);
  }
`
export default ProviderBannerItem
