import Box from 'UIKit/Box/Box'
import Select, { StyledExpansitionIconContainer } from 'UIKit/Select'
import styled from 'styled-components'

export const StyledSelect = styled(Select)`
  padding: 8px 12px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.04);
  font-size: 14px;
`

export const WrapperFilter = styled(Box)`
  font-size: 14px;

  .select-dropdown {
    font-size: 14px;
    border: none;

    border-radius: ${({ theme }) => theme.radii.tiny};
    background: #22262e;

    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    .option-items {
      padding: 10px 16px;

      &:focus-visible {
        background-color: transparent;
      }
    }
  }

  .date-range-container {
    border-radius: ${({ theme }) => theme.radii.tiny};
    background: rgba(255, 255, 255, 0.04);
    height: 42px;

    min-width: 120px;
  }

  ${StyledExpansitionIconContainer} {
    margin: 0;
    padding-bottom: 0;
  }
`
