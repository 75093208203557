import Box from 'UIKit/Box/Box'
import React, { useEffect, useState } from 'react'
import theme from 'theme'
import { colors } from 'theme/colors'

const LoadingTable: React.FC<{ loading?: boolean; disabled?: boolean; fixed?: boolean; top?: number | string }> = ({
  loading,
  disabled,
  fixed,
  top,
}) => {
  const [loaderProcess, setLoaderProcess] = useState(loading ? 0 : 100)

  useEffect(() => {
    if (disabled) {
      setLoaderProcess(101)
      return
    }

    if (!loading) {
      setLoaderProcess(101)
      return
    }
    if (loaderProcess > 0) return

    const timeout1 = setTimeout(() => setLoaderProcess(70), 100)
    const timeout2 = setTimeout(() => setLoaderProcess(80), 400)
    const timeout3 = setTimeout(() => setLoaderProcess(85), 500)
    const timeout4 = setTimeout(() => setLoaderProcess(95), 550)

    return () => {
      clearTimeout(timeout1)
      clearTimeout(timeout2)
      clearTimeout(timeout3)
      clearTimeout(timeout4)
    }
  }, [loading, disabled])

  return (
    <Box
      style={{ transition: '0.5s' }}
      width={`${loaderProcess}%`}
      height="2px"
      background={colors.primary}
      position={fixed ? 'fixed' : 'absolute'}
      display={disabled || loaderProcess > 100 ? 'none' : 'block'}
      top={top || `calc(${theme.topbarHeight})`}
    />
  )
}

export default LoadingTable
